<template>
  <v-container fluid class="pa-0" style="overflow-x: hidden;">
    <app-layout image="BG-noticias.png">

      <template #title>
        <div class="d-flex justify-center align-center flex-column">
          <v-card-title class="subtitle-2 text-sm-h6 text-md-h5 text-lg-h3 white--text mb-n2 mb-lg-4 font-weight-black text-center">
          Diario El Salvador
        </v-card-title>
          <p class="white--text text-body-1 text-sm-h6 text-md-h4 text-lg-h2 text-xl-h1 font-weight-bold pa-0 ma-0">
            Comprometidos con llevar</p>
          <p class="white--text text-body-1 text-sm-h6  text-md-h4 text-lg-h2 text-xl-h1 font-weight-bold pa-0 ma-0">
            la verdad a nuestra audiencia</p>
        </div>
      </template>


      <!-- </componenteinicio> -->
      <template #body-content>

        <!--Noticia principal -->
        <v-card flat class="mb-3 mt-3 ">
          <v-row class="d-flex justify-center">
            <!-- Inputs de busqueda de noticiaa -->
            <v-col cols="12" lg="12">
              <v-card flat class=" mt-5 ml-3 mr-3">
                <v-row class="d-flex justify-end">
                  <v-col cols="12" lg="3" sm="12" md="12">
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field color="blue" outlined rounded readonly v-model="date" label="Fecha de publicación"
                          append-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="date" :max="currentDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancelar
                        </v-btn>
                        <v-btn text color="primary" @click="BuscarNoticias(date)">
                          Buscar
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" lg="3" sm="12" md="12">
                    <v-text-field color="blue" outlined rounded append-icon="mdi-magnify" v-model="BuscarNoti"
                      @keypress="BuscarTitulo(BuscarNoti)" />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <!-- noticia principal -->
            <v-col cols="12" lg="12">
              <v-card flat height="450" v-show="Noticias.length == 0">
                <v-card-text>
                  <b class="black--text d-flex justify-center">No se encontraron noticias</b>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="12" class="d-flex justify-center">
              <v-card class="borde d-flex justify-center " @click="VerNoticia(Noticias[0])" elevation="5" width="1580"
                v-if="!Noticias.length == 0">
                <v-row no-gutters>

                  <v-col cols="12" lg="5" sm="12" md="12">
                    <img :src="Noticias[0]?.jetpack_featured_media_url" height="100%" width="600px" max-width="600"
                      contain style="height: 100% !important;" class="borde imgprincipal" />
                    <!-- <v-btn
                                readonly
                                class="ml-4 mt-5"
                                color="blue"
                                dark
                                small>
                                DelInnovacion
                            </v-btn> -->

                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-card flat style="padding-right: 0;">
                      <v-card-title class="blue--text text-h4">
                        <b class="adaptable limited-lines">{{ Noticias[0]?.title.rendered }}</b>
                      </v-card-title>
                      <v-card-text class="text-h6 " v-html="Noticias[0]?.excerpt.rendered">
                      </v-card-text>

                      <v-card-subtitle>
                        Diario El Salvador | {{ moment(Noticias[0]?.date).format('LL') }}
                      </v-card-subtitle>

                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" lg="12">
              <div class="d-flex justify-center">
                <!-- Seccion de noticias -->
                <v-card flat width="1820">
                  <v-row class="d-flex justify-center mt-16">
                    <v-col cols="12" lg="3" sm="6" md="4" v-for="(item, index) in Noticias" :key="index" v-if="index > 0"
                      class="card-margin">
                      <div style="position:relative;">
                        <v-card class="borde d-flex justify-center alturaCard" @click="VerNoticia(item)" elevation="4">
                          <v-row>
                            <v-col cols="12" lg="12" class="pb-0">
                              <img style="border-top-left-radius: 20px; border-top-right-radius: 20px;"
                                :src="item?.jetpack_featured_media_url" contain width="458px" class="img">
                              <v-btn readonly class="ml-4 mt-3" color="blue" dark small
                                style="position: absolute; top: 10px; left: 10px;">
                                De Innovación
                              </v-btn>

                              <v-card-title class="blue--text">
                                <b class="adaptable">{{ item?.title.rendered }}</b>
                              </v-card-title>

                              <v-card-text v-html="item?.excerpt.rendered" class="limited-lines">
                              </v-card-text>
                            </v-col>
                            <v-col cols="12" lg="12">
                              <v-card-subtitle class="subtitle-bottom">
                                Diario EI Salvador | {{ moment(item.date).format('LL') }}
                              </v-card-subtitle>
                            </v-col>
                          </v-row>
                        </v-card>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-card-actions class="d-flex justify-center">
                        <v-btn color="#30a4f1" dark rounded width="150" x-large class="text-h6" @click="MasNoticias()"
                          v-show="mostrarBoton" v-if="!Noticias.length == 0"><b>Ver más</b></v-btn>
                      </v-card-actions>
                    </v-col>

                  </v-row>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-card>


      </template>
    </app-layout>
  </v-container>
</template>
<script>
import axios from "axios";
// import componenteinicio from "../../components/AppHeaderBanner.vue";
import AppLayout from "../../components/AppLayoutComponent.vue";
import moment from "moment-timezone";
import { computed } from 'vue';

export default {
  name: "noticias",
  components: {
    // componenteinicio,
    AppLayout,
  },
  data: () => ({
    mostrarBoton: true,
    BuscarNoti: null,
    Noticias: {},
    xmlData: null,
    date: "",
    menu: false,
    modal: false,
    menu2: false,
    currentDate: new Date().toISOString().substr(0, 10),
  }),


  methods: {
    search(value) {
      // console.log(value);
    },

    MasNoticias() {
      axios.get('https://diarioelsalvador.com/wp-json/wp/v2/posts?categories=34').then(response => {
        this.Noticias = response.data;
        this.mostrarBoton = false
      })
    },

    BuscarTitulo(item) {
      let buscar = item
      
      if (buscar != "") {
        axios.get(`https://diarioelsalvador.com/wp-json/wp/v2/posts?categories=34&search=${buscar} `).then(response => {
          if (response.status == 200) {
            this.Noticias = response.data;
          } else {

          }
        })
      }
      else {
        this.listadoNoticias()
      }
    },

    BuscarNoticias(fecha) {

      let before = fecha
      let after = fecha

      let datos = {
        antes: moment(before).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss'),
        despues: moment(after).hour(0).minute(0).second(1).format('YYYY-MM-DDTHH:mm:ss')
      }

      axios.get(`https://diarioelsalvador.com/wp-json/wp/v2/posts?categories=34&&before=${datos.antes}&after=${datos.despues} `).then(response => {
        if (response.status == 200) {
          this.Noticias = response.data;
          this.menu = false
        } else {

        }
      })
    },

    VerNoticia(noticia) {
      this.$router.push({
        name: `noticia_princial`,
        params: { noticia: noticia, id: noticia.id }
      })
    },

    async listadoNoticias() {
      try {
        const response = await axios.get('https://diarioelsalvador.com/wp-json/wp/v2/posts', {
          params: {
            categories: 34,
            per_page: 4 // Limit the number of posts to 3
          }
        });

        this.Noticias = response.data;
      } catch (error) {

        //console.error('Error fetching news articles:', error);
      }
    },
    formattedMonth(date) {
      const monthNames = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      const month = date.getMonth();
      return monthNames[month];
    },
  },

  watch:{
    BuscarNoti:{
      handler(e){
        if(e=="")
        {
          this.listadoNoticias()
        }
      }
    }
  },

  created() {
    this.listadoNoticias();
  },
};
</script>

<style scoped>
.rounded {
  border-radius: 1500px;
}

.borde {
  border-radius: 20px;
}

.borde-paralax {
  border-radius: 0 0 15% 15% / 0 0 15% 15%;
}

.titulo {
  word-break: break-word;
}

img {
  max-width: 100%;
  height: auto;
  width: 657px;
  height: 220px;
}

.alturaCard {
  height: 620px;
}

.imgprincipal {
  max-width: 100%;
  height: auto;
  width: 1036px;
  height: 250px;
}

.adaptable {
  overflow-wrap: break-word;
  word-break: break-word;
}

.subtitle-bottom {
  position: absolute;
  bottom: 0;
  margin-top: 20px;
}

@media (max-width: 200px) {
  .subtitle-bottom {
    position: static;
  }
}

@media (min-width: 600px) {
  .card-margin {
    margin: 55px;
  }
}

@media (max-width: 600px) {
  img {
    width: 100%;
  }
}

.limited-lines {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  /*Número de líneas que deseas mostrar */
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(1.5em * 7);
  /* Altura máxima que ocupa 4 líneas */
  line-height: 1.5em;
  /* Altura de línea */
}
</style>




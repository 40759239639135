<template>
  <v-container fluid class="pa-0" style="overflow: hidden !important; ">
    <app-layout image="BG-Empleos.svg">

      <template #title>
        <div class="d-flex justify-center align-center flex-column">
          <v-card-title class="subtitle-2 text-sm-h6 text-md-h5 text-lg-h3 white--text mb-n2 mb-lg-4 font-weight-black text-center">
          Diario El Salvador
        </v-card-title>
          <p class="white--text text-body-1 text-sm-h6 text-md-h4 text-lg-h2 text-xl-h1 font-weight-bold pa-0 ma-0">
            Comprometidos con llevar</p>
          <p class="white--text text-body-1 text-sm-h6  text-md-h4 text-lg-h2 text-xl-h1 font-weight-bold pa-0 ma-0">
            la verdad a nuestra audiencia</p>
        </div>
      </template>

      <template #body-content>
        <!--Noticia principal -->
        <v-card flat>
          <v-row class="d-flex justify-center">
            <v-col cols="12" lg="10">
              <v-card-actions>
                <v-btn rounded color="blue" dark x-large @click="Regresar()">Regresar</v-btn>
              </v-card-actions>
              <v-card-title class="blue--text text-h4 titulo">
                <b>{{ noticia.title.rendered }}</b>
              </v-card-title>
            </v-col>
            <v-col cols="12" lg="10">
              <v-card flat>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <div class=".img-Noticiacontainer">
                      <img class="imgPrincipal " :src="noticia.jetpack_featured_media_url" />
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card-text class="scrollable" v-html="noticia.content.rendered">
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>

        <!-- <v-card flat>
          <v-row class="d-flex justify-left">
            <v-col cols="12" lg="12">
              <v-card-title class="blue--text text-h4 titulo">
                <b style="margin-left: 55px;">{{ noticia.title.rendered }}</b>
              </v-card-title>
            </v-col>
            <v-col cols="12" lg="12">
              <v-card flat >
                <v-row class="d-flex justify-center">
                  <v-col cols="12" lg="5">
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <img
                        class="imgPrincipal"
                        :src="noticia.jetpack_featured_media_url"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-card-text
                      class="scrollable"
                      v-html="noticia.content.rendered"
                    >
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card> -->

        <div class="d-flex justify-center">
          <!-- Seccion de noticias -->
          <v-card flat width="1820">
            <!-- Inputs para buscar noticias -->
            <v-row class="mt-16">
              <v-col cols="12" lg="2" sm="12" md="12" class="custom-margin">
                <v-card-text class="grey--text text-h4 mt-5 ml-3 mr-3">
                  <b>Más noticias</b>
                </v-card-text>
              </v-col>
              <v-col cols="12" lg="9" sm="12" md="12">
                <v-card flat class="mt-5 ml-3 mr-3">
                  <v-row class="d-flex justify-end">
                    <v-col cols="12" lg="4" sm="12" md="12">
                      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field color="blue" outlined rounded readonly v-model="date" label="Fecha de publicación"
                            append-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="date" :max="currentDate" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancelar
                          </v-btn>
                          <v-btn text color="primary" @click="BuscarNoticias(date)">
                            Buscar
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" lg="4" sm="12" md="12">
                      <v-text-field outlined rounded v-model="Buscador" color="textCyan" append-icon="mdi-magnify"
                        @keyup="BuscarTitulo(Buscador)" class="inputStyle"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <!--card noticias -->
            <v-row class="d-flex justify-center">
              <v-col cols="12" lg="12" sm="12" md="12">
                <v-card-text v-show="ListaNoticias.length == 0">
                  <h3 class="d-flex justify-center">No se encontraron noticias</h3>
                </v-card-text>
              </v-col>
              <v-col cols="12" lg="3" sm="6" md="4" v-for="(item, index) in ListaNoticias" :key="index" v-if="index > 0"
                class="card-margin mt-0">
                <div style="position:relative;">
                  <v-card class="borde d-flex justify-center alturaCard" @click="VerNoticia(item)" elevation="4">
                    <v-row>
                      <v-col cols="12" lg="12" class="pb-0">
                        <img style="border-top-left-radius: 20px; border-top-right-radius: 20px;"
                          :src="item?.jetpack_featured_media_url" contain width="458px" class="img">
                        <v-btn readonly class="ml-4 mt-3" color="blue" dark small
                          style="position: absolute; top: 10px; left: 10px;">
                          De Innovación
                        </v-btn>

                        <v-card-title class="blue--text">
                          <b class="adaptable">{{ item?.title.rendered }}</b>
                        </v-card-title>

                        <v-card-text v-html="item?.excerpt.rendered" class="limited-lines">
                        </v-card-text>
                      </v-col>
                      <v-col cols="12" lg="12">
                        <v-card-subtitle class="subtitle-bottom">
                          Diario EI Salvador | {{ moment(item.date).format('LL') }}
                        </v-card-subtitle>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </v-col>

            </v-row>
          </v-card>
        </div>



      </template>

    </app-layout>
  </v-container>
</template>
    
<script>
import axios from "axios";
import moment from "moment-timezone";
//import componenteinicio from "../../components/AppHeaderBanner.vue"
// import complemento from "../../components/AppLayoutComponent.vue";
import layoutComponent from '@/components/AppLayoutComponent.vue'
import AppLayout from "../../components/AppLayoutComponent.vue";



export default {




  name: "Ventanilla",
  components: {
    //componenteinicio,
    // complemento,
    AppLayout

  },
  data: () => ({
    Buscador: null,
    noticia2: {},
    noticia: {},
    ListaNoticias: null,
    xmlData: null,
    date: null,
    menu: false,
    modal: false,
    menu2: false,
    currentDate: new Date().toISOString().substr(0, 10),
  }),

  // props:{
  //     noticia:{},
  // },

  methods: {
    search(value) {
      // console.log(value);
    },
    Regresar() {
      this.$router.push("/noticias");
    },

    VerNoticia(noticia) {
      this.$router.push({
        name: `noticia_princial`,
        params: { noticia: noticia, id: noticia.id },
      });
    },

    BuscarTitulo(item) {
      let buscar = item;

      if (buscar != "") {
        axios.get(`https://diarioelsalvador.com/wp-json/wp/v2/posts?categories=34&search=${buscar} `).then(response => {
          if (response.status == 200) {
            this.Noticias = response.data;
          } else {

          }
        })
      }
      else {
        this.listadoNoticias()
      }
    },

    BuscarNoticias(fecha) {
      let before = fecha;
      let after = fecha;

      let datos = {
        antes: moment(before)
          .hour(23)
          .minute(59)
          .second(59)
          .format("YYYY-MM-DDTHH:mm:ss"),
        despues: moment(after)
          .hour(0)
          .minute(0)
          .second(1)
          .format("YYYY-MM-DDTHH:mm:ss"),
      };

      axios
        .get(
          `https://diarioelsalvador.com/wp-json/wp/v2/posts?categories=34&&before=${datos.antes}&after=${datos.despues} `
        )
        .then((response) => {
          if (response.status == 200) {
            this.menu = false
            this.ListaNoticias = response.data;

          } else {
          }
        });
    },

    async listadoNoticias() {
      axios
        .get("https://diarioelsalvador.com/wp-json/wp/v2/posts?categories=34")
        .then((response) => {
          this.ListaNoticias = response.data;
        });
    },

  },
  watch:{
    BuscarNoti:{
      handler(e){
        if(e=="")
        {
          this.listadoNoticias()
        }
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.listadoNoticias();
    this.noticia = this.$route.params.noticia;
  },
};
</script>
    
<style scoped>
.rounded {
  border-radius: 1500px;
}

.borde {
  border-radius: 20px;
}

.borde-paralax {
  border-radius: 0 0 15% 15% / 0 0 15% 15%;

}

.borde-paralax {
  border-radius: 0 0 15% 15% / 0 0 15% 15%;
}

.titulo {
  word-break: break-word;
}

.palabra {
  word-wrap: break-word;
}

.imgPrincipal {
  max-width: 100%;
  height: auto;
  border-radius: 25px;
  width: 757px;
  height: 450px;
}

img {
  max-width: 100%;
  height: auto;

  width: 757px;
  height: 255px;
}

.textoTitulo {
  font-weight: bold;
}

.adaptable {
  overflow-wrap: break-word;
  word-break: break-word;
}

.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 450px;
}

.scrollable::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #60cefa;
}

/* body{
    overflow: hidden !important; 
} */

.alturaCard {
  height: 620px;
}

.inputStyle ::v-deep i::before {
  color: #30A4F1 !important;
}

.inputStyle ::v-deep label {
  color: #30A4F1 !important;
}

.subtitle-bottom {
  position: absolute;
  bottom: 0;
  margin-top: 20px;
}

@media (max-width: 200px) {
  .subtitle-bottom {
    position: static;
  }


}

.limited-lines {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /*Número de líneas que deseas mostrar */
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(1.5em * 5);
  /* Altura máxima que ocupa 4 líneas */
  line-height: 1.5em;
  /* Altura de línea */
}

@media (max-width: 500px) {
  img {
    width: 100%;
  }

  .imgPrincipal {
    width: 100%;
  }
}

@media (min-width: 600px) {
  .card-margin {

    margin: 55px;
  }
}

@media (min-width: 1280px) {
  .custom-margin {
    margin-left: 60px;
  }
}

.img-Noticiacontainer {
  width: 400px;
  /* Ajustar con las dimensiones reales de la imagen */
  height: 300px;
}

.imgPrincipal {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* .espacio{
    margin: 0;
} */
</style>
    
    
    